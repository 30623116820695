<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="70%"
    >
      <el-form
        :model="addForm"
        label-width="100px"
        :rules="rules"
        ref="addForm"
        class="demo-addForm"
      >
       <el-button type="primary" @click="cancelForm()">取消</el-button>
        <el-button type="primary" @click="EditLvieFrom()">确 定</el-button>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="表名称" prop="functionName">
              <el-input v-model="addForm.functionName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="表注释">
              <el-input v-model="addForm.tableDesc" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="作者">
              <el-input v-model="addForm.author" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <!-- <el-col :span="12">
            <el-form-item label="跟路径"  prop="author">
                <el-input v-model="addForm.followPath" ></el-input>
            </el-form-item>
          </el-col>-->
          <el-col :span="8">
            <el-form-item label="功能路径">
              <el-input v-model="addForm.apiName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="功能名称" prop="author">
              <el-input v-model="addForm.functionName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="功能注解">
              <el-input v-model="addForm.functionDesc"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="生成方式" prop="generationType">
              <el-radio-group v-model="addForm.generationType">
                <el-radio :label="0">zip压缩包下载</el-radio>
                <el-radio :label="1" disabled>自定义路径下载</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="包路径" prop="packageName">
              <el-input v-model="addForm.packageName"></el-input>
            </el-form-item>
          </el-col>
            <el-col :span="8">
            <el-form-item label="模块名称" prop="modularData">
              <el-input v-model="addForm.modularData"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addForm.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-tabs type="border-card">
          <el-tab-pane label="字段信息">
            <el-table
              :data="generationTableColumnVO"
              style="width: 100%"
              max-width="400px"
              height="850"
            >
              <el-table-column fixed type="index" :index="indexMethod" label="序号"></el-table-column>
              <el-table-column fixed label="数据库字段名" align="center" prop="columnName" />
              <el-table-column fixed label="物理类型" align="center" prop="dataType" />
              <el-table-column fixed label="java类型" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item>
                      <el-input v-model="scope.row.javaType">{{scope.row.javaType}}</el-input>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column fixed label="字段描述" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item>
                      <el-input v-model="scope.row.columnComment">{{scope.row.columnComment}}</el-input>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column fixed label="是否插入" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item>
                      <el-checkbox v-model="scope.row.isInsert" :true-label="1" :false-label="2">是</el-checkbox>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>

              <el-table-column fixed label="是否编辑" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item prop="isEdit">
                      <el-checkbox v-model="scope.row.isEdit" :true-label="1" :false-label="2">是</el-checkbox>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>

              <el-table-column fixed label="是否查询" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item prop="isQuery">
                      <el-checkbox v-model="scope.row.isQuery" :true-label="1" :false-label="2">是</el-checkbox>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>

              <el-table-column fixed label="列表是否展示" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item prop="isShow">
                      <el-checkbox v-model="scope.row.isShow" :true-label="1" :false-label="2">是</el-checkbox>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>

              <el-table-column fixed label="是否校验" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item prop="isValidate">
                      <el-checkbox v-model="scope.row.isValidate" :true-label="1" :false-label="2">是</el-checkbox>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>

              <el-table-column fixed label="查询方式" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item prop="queryType">
                      <el-select v-model="scope.row.queryType" placeholder="请选择">
                        <el-option
                          v-for="item in queryTypeList"
                          :key="item.key"
                          :label="item.value"
                          :value="item.key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>

              <el-table-column fixed label="字典code" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item prop="dictTypeCode">
                      <el-select v-model="scope.row.dictTypeCode" placeholder="请选择">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>

              <el-table-column fixed label="控件类型" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item>
                      <el-select v-model="scope.row.controlType" placeholder="请选择">
                        <el-option
                          v-for="item in controlTypeEnumList"
                       :key="item.key"
                          :label="item.value"
                          :value="item.key"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>

              <el-table-column fixed label="校验规则" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item>
                      <el-input v-model="scope.row.validateRules">{{scope.row.validateRules}}</el-input>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="模板配置">
            <el-table
              :data="generationDepositPositionList"
              style="width: 100%"
              max-width="400px"
              height="850"
            >
              >
              <el-table-column fixed type="index" :index="indexMethod" label="序号" />
              <el-table-column fixed label="生成类型" align="center" prop="generationTempType" />
              <el-table-column fixed label="选择模板" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-select v-model="scope.row.tempKey" placeholder="请选择">
                      <el-option
                        v-for="item in tempDataList"
                        :key="item.key"
                        :label="item.groupName+'-'+item.label"
                        :value="item.key"
                      ></el-option>
                    </el-select>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column fixed label="包路径" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item>
                      <el-input v-model="scope.row.packageName">{{scope.row.packageName}}</el-input>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>

              <el-table-column fixed label="文件存放位置" align="center">
                <template slot-scope="scope">
                  <el-form :model="scope.row">
                    <el-form-item>
                      <el-input
                        v-model="scope.row.generationFilePath"
                      >{{scope.row.generationFilePath}}</el-input>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <span>
          <el-button @click="cancelForm()">取 消</el-button>
          <el-button type="primary">确 定</el-button>
        </span>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
import { getEnumList } from "@/api/commonApi/commonInternal/commonInternal";
import {
  selectCodeGenerationById,
  editCodeGeneration
} from "@/api/system/tableGeneration/tables.js";
export default {
  data() {
    return {
      dialog: false,
      title: "编辑",
      rules: {},
      addForm: {},
      id: null,
      tempVO: {},
      generationDepositPositionList: [],
      generationTableColumnVO: [],
      options: [],
      queryTypeList: [],
      tempDataList: [],
      editFromData: {},
      controlTypeEnumList:[],
    };
  },
  methods: {
    show(row) {
      console.log(row);
      this.dialog = true;
      this.queryTypeEnumList();
      this.tempDataEnumList();
      this.ControlTypeEnumList();
      this.id = row.id;
      this.selectCodeGenerationButton(this.id);
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.dialog = false;
      //   this.$parent.menuListButton();
    },
    indexMethod(index) {
      return index + 1;
    },
    queryTypeEnumList() {
      getEnumList({ enumName: "QueryTypeEnum" }).then(resp => {
        this.queryTypeList = resp.result;
      });
    },
    tempDataEnumList() {
      getEnumList({ enumName: "TempDataEnum" }).then(resp => {
        this.tempDataList = resp.result;
      });
    },
      ControlTypeEnumList() {
      getEnumList({ enumName: "ControlTypeEnum" }).then(resp => {
        this.controlTypeEnumList = resp.result;
      });
    },
    selectCodeGenerationButton(id) {
      selectCodeGenerationById(id).then(res => {
        console.log("data:{}", res);
        this.addForm = res.result.generationBasicInfoVO;
        this.generationTableColumnVO = res.result.generationTableColumnVO;
        this.generationDepositPositionList =
          res.result.generationDepositPositionVO;
      });
    },
    EditLvieFrom() {
      this.editFromData = {
        generationBasicInfoVO: this.addForm,
        generationDepositPositionVO: this.generationDepositPositionList,
        generationTableColumnVO: this.generationTableColumnVO
      };
      editCodeGeneration(this.editFromData).then(res => {
        console.log(res)
        this.cancelForm();
      });
    }
  }
};
</script>
<style scoped>
</style>