<template>
  <div class="app-container">
    <!-- 查询区域 -->
    <div>
      <el-form :model="queryParams" layout="inline" :inline="true">
        <el-row :gutter="24">
          <el-col :md="6" :sm="8">
            <el-form-item label="记录月份">
              <el-input
                placeholder="请输入"
                style="width: 60%"
                v-model="queryParams.recordData"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :md="6" :sm="8">
            <el-form-item label="工资月份">
              <el-input
                placeholder="请输入"
                style="width: 60%"
                v-model="queryParams.userCompany"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="是否全部收入">
              <el-input placeholder="请输入" style="width: 60%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="6" :sm="8">
            <el-form-item label="是否全部收入">
              <el-input placeholder="请输入" style="width: 60%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 这段代码是隐藏查询功能的-->
        <el-row :gutter="24">
          <template v-if="toggleSearchStatus">
            <el-col :md="6" :sm="8">
              <el-form-item label="子表状态">
                <el-input placeholder="请输入" style="width: 60%"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <!-- <el-col :md="!toggleSearchStatus && 20|| 20" :sm="24">
            <span :style="handleToggleSearch && { float: 'right', overflow: 'hidden' } || {} ">
              <el-button
                type="primary"
              >{{ toggleSearchStatus ? '高级查询' : '搜索' }}</el-button>
              <el-button type="primary">重置</el-button>
              <a @click="handleToggleSearch">
                {{ toggleSearchStatus ? '收起' : '高级查询' }}
                <el-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
          </el-col>-->
        </el-row>
      </el-form>
    </div>
    <el-form
      :model="queryParams"
      ref="queryForm"
      :inline="true"
      label-width="68px"
    >
      <el-form-item label="业务标识" prop="tableName">
        <el-input
          v-model="queryParams.tableName"
          placeholder="请输入业务标识"
          clearable
          size="small"
        />
      </el-form-item>
      <el-form-item label="发送时间" prop="sendTime">
        <el-date-picker
          clearable
          size="small"
          style="width: 200px"
          v-model="queryParams.sendTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择发送时间"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button
          type="cyan"
          icon="el-icon-search"
          size="mini"
          @click="selectCodeGenerationListButton()"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="importTable()"
          >导入</el-button
        >
      </el-col>
    </el-row>

    <el-table :data="codeList">
      <el-table-column label="序号" align="center" width="65">
        <template slot-scope="scope">
          <el-radio
            :label="scope.$index"
            v-model="radio"
            @change.native="getCurrentRow(scope.row)"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="表名称" align="center" prop="tableName" />
      <el-table-column label="版本" align="center" prop="version" />
      <el-table-column label="表的注释" align="center" prop="tableDesc" />
      <el-table-column label="创建时间" align="center" prop="createdTime" />
      <el-table-column label="更新时间" align="center" prop="modifiedTime" />
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-setting"
            @click="configTableShow(scope.row)"
            >设置</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-download"
            @click="downloadResWordButton(scope.row)"
            >点击生成</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            >删除</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            disabled
            >预览</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />

    <importTable ref="importFrom" />
    <configTable ref="configFrom" />
  </div>
</template>

<script>
import importTable from "./modules/importTable";
import configTable from "./modules/configTable";
import { selectCodeGenerationList } from "@/api/system/tableGeneration/tables.js";
import { downLoadZip } from "@/utils/zipdownload";
export default {
  name: "tableIndex",
  components: {
    importTable,
    configTable,
  },
  data() {
    return {
      // 选中数组
      ids: [],
      radio: null,
      // 验证码表格数据
      codeList: [],
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      total: 0,
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      toggleSearchStatus: false,
      testParams: {
        tableName: null,
        newPath: null,
      },
    };
  },
  created() {
    this.selectCodeGenerationListButton();
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectCodeGenerationListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectCodeGenerationListButton();
    },
  },
  methods: {
    getTableListButton() {},
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.id = row.tableName;
      this.testParams = row;
    },
    importTable() {
      this.$refs.importFrom.show();
    },
    configTableShow(row) {
      this.$refs.configFrom.show(row);
    },
    selectCodeGenerationListButton() {
      selectCodeGenerationList(this.queryParams).then((res) => {
        this.codeList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    handleToggleSearch() {
      this.toggleSearchStatus = !this.toggleSearchStatus;
      console.log(this.toggleSearchStatus);
    },
    downloadResWordButton(row) {
      downLoadZip(
        "/xun/common/xunTableCommon/downloadResWord?id=" + row.id,
        "xun"
      );
    },
  },
};
</script>
